.app {
  @import './themes/generated/variables.base.scss';
  background-color: darken($base-bg, 5);
  display: flex;
  height: 100%;
  width: 100%;
}

.input-label {
  font-size: 14px;
  font-weight: bold;
}

.form-container {
  display: flex;

  .form-input {
    flex-grow: 1;
    margin-bottom: 25px;
  }

  label {
    font-size: 15px;
    font-weight: bold;
    width: 150px;
  }

  .required {
    color: red;
    font-size: 10px;
    vertical-align: super;
  }
}
