@import '../../themes/generated/variables.base.scss';

.header {
  text-align: center;
  margin-bottom: 40px;
}

.title {
  color: $base-accent;
  font-weight: bold;
  font-size: 30px;
  margin: 0;
}

.button {
  margin-top: 4rem;
}
