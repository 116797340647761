.container {
  align-items: center;
  display: flex;
}

.pageHeader {
  /* background-color: white; */
  height: 10%;
  padding: 1rem;
}

.icon {
  font-size: 2rem;
}

.text {
  font-size: 1.25rem;
  font-weight: bold;
  margin-left: 0.5rem;
}
